.kw-team-row::after {
  content: "";
  clear: both;
  display: table;
  color: #000000;
}

.kw-team-card {
  float: left;
  min-height: 180px;
  max-height: 200px;
  //width: 20%;
  aspect-ratio: 1;
  margin-bottom: 16px;
  margin-left: 1%;
  &--demo {
    @extend .kw-team-card;
    min-height: 165px;
  }

  .kw-team-img-container {
    display: inline-block;
  }

  .kv-team-img {
    height: 110px;
    width: 110px;
    float: left;
    //margin-right: 5px;
    vertical-align: center;
    object-fit: cover;
  }

  .kw-team-text > label {
    max-width: none;
    margin-bottom: 0;
    //white-space: ;
  }

  .kw-team-text > span {
    display: block;
  }

  @media all and (max-width: 1100px) {
    .kv-team-img {
      height: 85px;
      width: 85px;
    }

    .kw-team-row {
      display: flex;
      flex-flow: row;
    }
    .kw-team-card {
      max-height: 300px;
      width: 20%;
      aspect-ratio: auto;
    }
  }

  @media screen and (max-width: 599px) {
    .kw-team-row {
      display: flex;
      flex-flow: column wrap;
    }
    .kw-team-card {
      max-height: 300px;
      width: 100%;
      aspect-ratio: auto;
    }
  }
}
